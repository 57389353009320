import React from "react";
import NodeArtPreviewTeaser from "components/Art/NodeArtPreviewTeaser";
import { Transition } from "@headlessui/react";

export default function NodeArtPreviewList({ isInViewport, nodes }) {
  return (
    <>
      <Transition
        show={isInViewport}
        className="hidden lg:block w-1/2 px-[25px]"
      >
        <div className="flex justify-end -mx-[25px]">
          <Transition.Child
            enter="transition-all duration-500 delay-300"
            enterFrom="opacity-0 -translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-10"
            className="hidden xl:block w-[36.5%] px-[25px]"
          >
            <NodeArtPreviewTeaser node={nodes[0]} narrow />
          </Transition.Child>
          <Transition.Child
            enter="transition-all duration-500 delay-300"
            enterFrom="opacity-0 -translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-10"
            className="w-2/3 xl:w-1/2 px-[25px]"
          >
            <NodeArtPreviewTeaser node={nodes[1]} />
          </Transition.Child>
        </div>
      </Transition>
      <Transition
        show={isInViewport}
        className="hidden lg:block w-full px-[25px] mt-[50px]"
      >
        <div className="flex justify-end -mx-[25px]">
          <Transition.Child
            enter="transition-all duration-500 delay-500"
            enterFrom="opacity-0 translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-10"
            className="block xl:hidden w-3/12 px-[25px]"
          >
            <NodeArtPreviewTeaser node={nodes[0]} narrow />
          </Transition.Child>
          <Transition.Child
            enter="transition-all duration-500 delay-500"
            enterFrom="opacity-0 translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-10"
            className="w-4/12 xl:w-3/12 px-[25px]"
          >
            <NodeArtPreviewTeaser node={nodes[2]} />
          </Transition.Child>
          <Transition.Child
            enter="transition-all duration-500 delay-500"
            enterFrom="opacity-0 translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-10"
            className="w-3/12 xl:w-[18.34%] px-[25px]"
          >
            <NodeArtPreviewTeaser node={nodes[3]} narrow />
          </Transition.Child>
          <Transition.Child
            enter="transition-all duration-500 delay-500"
            enterFrom="opacity-0 translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-10"
            className="hidden xl:block w-3/12 px-[25px]"
          >
            <NodeArtPreviewTeaser node={nodes[4]} />
          </Transition.Child>
          <Transition.Child
            enter="transition-all duration-500 delay-500"
            enterFrom="opacity-0 translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-10"
            className="hidden xl:block w-[18.34%] px-[25px]"
          >
            <NodeArtPreviewTeaser node={nodes[5]} narrow />
          </Transition.Child>
        </div>
      </Transition>
      <Transition
        show={isInViewport}
        className="hidden lg:block xl:hidden w-full px-[25px] mt-[50px]"
      >
        <div className="flex -mx-[25px]">
          <Transition.Child
            enter="transition-all duration-500 delay-500"
            enterFrom="opacity-0 translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-10"
            className="w-4/12 px-[25px]"
          >
            <NodeArtPreviewTeaser node={nodes[4]} />
          </Transition.Child>
          <Transition.Child
            enter="transition-all duration-500 delay-500"
            enterFrom="opacity-0 translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-10"
            className="w-3/12 px-[25px]"
          >
            <NodeArtPreviewTeaser node={nodes[5]} narrow />
          </Transition.Child>
        </div>
      </Transition>
      <div className="block lg:hidden w-full px-[25px] mt-[50px]">
        <div className="flex flex-wrap -mx-[25px]">
          {nodes.map((node) => {
            return (
              <div
                key={node.id}
                className="block xl:hidden w-full md:w-3/12 px-[25px] mb-[50px]"
              >
                <NodeArtPreviewTeaser node={node} narrow />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
