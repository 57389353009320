import React, { useRef } from "react";
import Image from "next/image";
import { Parallax } from "react-scroll-parallax";
import { Transition } from "@headlessui/react";
import useIsInViewport from "hook/useIsInViewport";
import Body from "components/Body";
import { ArrowRightIcon } from "@heroicons/react/outline";
import Link from "next/link";

export default function NodeExpositionPreviewSection({ info }) {
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);

  return (
    <section
      id={info.field_anchor}
      className="relative py-20 lg:pt-40 lg:pb-48 bg-secondary-dark overflow-hidden"
    >
      <Parallax
        translateY={[-15, 15]}
        className="absolute inset-0 ml-[25%] lg:ml-[15%]"
        aria-hidden="true"
      >
        <Image
          src="/images/bg-05.jpg"
          width="1515"
          height="716"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-secondary-dark" />
        <div
          className="absolute bottom-0 right-0 w-[36.2vw] xl:h-[36.2vw] bg-primary blur-3xl opacity-40 rounded-full translate-y-1/2 translate-x-1/4"
          aria-hidden="true"
        />
      </Parallax>

      <div ref={ref} className="relative container mx-auto px-4">
        <Transition show={isInViewport} className="xl:w-1/2">
          <Transition.Child
            enter="transition-all duration-500 delay-300"
            enterFrom="opacity-0 translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-10"
            className="counter text-white"
          />

          <Transition.Child
            as="h2"
            enter="transition-all duration-500 delay-500"
            enterFrom="opacity-0 -translate-x-10"
            enterTo="opacity-100 translate-x-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 -translate-x-10"
            className="font-serif font-medium text-5xl mb-4"
          >
            <small className="block text-primary">Exposition</small>
            Mémoire Vive
          </Transition.Child>

          <Transition.Child
            enter="transition-all duration-500 delay-700"
            enterFrom="opacity-0 -translate-x-10"
            enterTo="opacity-100 translate-x-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 -translate-x-10"
            className="prose prose-white mx-0 max-w-none text-xl text-default-light mb-10"
          >
            <Body value={info.body.processed} />
          </Transition.Child>

          <Transition.Child
            enter="transition-all duration-500 delay-[900ms]"
            enterFrom="opacity-0 -translate-x-10"
            enterTo="opacity-100 translate-x-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 -translate-x-10"
            className="prose prose-white mx-0 max-w-none text-xl text-default-light mb-10"
          >
            <Link href="/expositions" passHref>
              <a className="group inline-flex items-center gap-4 no-underline">
                <span className="block p-6 rounded-full border border-secondary-light group-hover:bg-secondary-light transition-colors duration-300">
                  <ArrowRightIcon
                    className="h-4 w-4 text-primary"
                    aria-hidden="true"
                  />
                </span>
                <span className="uppercase text-sm font-semibold group-hover:text-primary transition-colors duration-300">
                  En savoir plus
                </span>
              </a>
            </Link>
          </Transition.Child>
        </Transition>
      </div>
    </section>
  );
}
