
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React from "react";
import { getResourceCollection } from "next-drupal";
import Layout from "components/Layout";
import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import { getGlobals } from "lib/getGlobals";
import Meta from "components/Meta";
import useTranslation from "next-translate/useTranslation";
import NodeArtPreviewSection from "components/Art/NodeArtPreviewSection";
import NodeFigurePreviewSection from "components/Figure/NodeFigurePreviewSection";
import NodeMilestonePreviewSection from "components/Milestone/NodeMilestonePreviewSection";
import NodePatrinomyPreviewSection from "components/Patrimony/NodePatrinomyPreviewSection";
import NodeExpositionPreviewSection from "components/Exposition/NodeExpositionPreviewSection";
import NodeSliceOfLifePreviewSection from "components/SliceOfLife/NodeSliceOfLifePreviewSection";

export default function IndexPage({ nodes, globals }) {
  const { t } = useTranslation("home");
  const bannersKeys =
    nodes.banners?.length > 0
      ? nodes.banners.map((banner, index) => index)
      : [];
  const hasMilestones = bannersKeys.includes(0) && nodes.milestones?.length > 0;
  const hasFigures = bannersKeys.includes(1) && nodes.figures?.length > 0;
  const hasArts = bannersKeys.includes(2) && nodes.arts?.length > 0;
  const hasPatrimony = bannersKeys.includes(3) && nodes.patrimony?.length > 0;
  const hasExposition = bannersKeys.includes(4);
  const hasSlicesOfLife = nodes.slicesOfLife?.length > 0;

  return (
    <Layout globals={globals} banners={nodes.banners} home>
      <Meta title={t("meta.title")} description={t("meta.description")} />

      {hasMilestones && (
        <NodeMilestonePreviewSection
          info={nodes.banners[0]}
          nodes={nodes.milestones}
        />
      )}

      {hasFigures && (
        <NodeFigurePreviewSection
          info={nodes.banners[1]}
          nodes={nodes.figures}
        />
      )}

      {hasArts && (
        <NodeArtPreviewSection info={nodes.banners[2]} nodes={nodes.arts} />
      )}

      {hasPatrimony && (
        <NodePatrinomyPreviewSection
          info={nodes.banners[3]}
          nodes={nodes.patrimony}
        />
      )}

      {hasExposition && (
        <NodeExpositionPreviewSection info={nodes.banners[4]} />
      )}

      {hasSlicesOfLife && (
        <NodeSliceOfLifePreviewSection nodes={nodes.slicesOfLife} />
      )}
    </Layout>
  );
}

 async function _getStaticProps(context) {
  const localeContext = {
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  };

  const bannersParams = new DrupalJsonApiParams();

  bannersParams
    .addInclude(["field_image"])
    .addFilter("status", 1)
    .addSort("field_weight", "ASC")
    .addPageLimit(5);

  const banners = await getResourceCollection("node--banner", {
    params: bannersParams.getQueryObject(),
    ...localeContext,
  });

  const milestonesParams = new DrupalJsonApiParams();

  milestonesParams
    .addInclude(["field_image"])
    .addFilter("status", 1)
    .addSort("field_weight", "ASC");

  const milestones = await getResourceCollection("node--milestone", {
    params: milestonesParams.getQueryObject(),
    ...localeContext,
  });

  const figuresParams = new DrupalJsonApiParams();

  figuresParams
    .addInclude(["field_image"])
    .addFilter("status", 1)
    .addPageLimit(5);

  const figures = await getResourceCollection("node--figure", {
    params: figuresParams.getQueryObject(),
    ...localeContext,
  });

  const artsParams = new DrupalJsonApiParams();

  artsParams.addInclude(["field_image"]).addFilter("status", 1).addPageLimit(6);

  const arts = await getResourceCollection("node--art", {
    params: artsParams.getQueryObject(),
    ...localeContext,
  });

  const patrimonyParams = new DrupalJsonApiParams();

  patrimonyParams
    .addInclude(["field_image"])
    .addFilter("status", 1)
    .addPageLimit(7);

  const patrimony = await getResourceCollection("node--patrimoine", {
    params: patrimonyParams.getQueryObject(),
    ...localeContext,
  });

  const slicesOfLifeParams = new DrupalJsonApiParams();

  slicesOfLifeParams
    .addInclude(["field_images"])
    .addFilter("status", 1)
    .addPageLimit(2);

  const slicesOfLife = await getResourceCollection("node--slice_of_life", {
    params: slicesOfLifeParams.getQueryObject(),
    ...localeContext,
  });

  return {
    props: {
      globals: await getGlobals(context),
      nodes: {
        banners,
        milestones,
        figures,
        arts,
        patrimony,
        slicesOfLife,
      },
      blocks: {},
    },
    revalidate: 30,
  };
}


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  