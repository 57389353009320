import React from "react";
import Image from "next/image";
import classNames from "lib/classNames";
import Link from "next/link";
import { useRouter } from "next/router";

export default function NodeArtPreviewTeaser({ node, narrow = false }) {
  const router = useRouter();

  return (
    <div
      className={classNames(
        "group relative overflow-hidden rounded-md cursor-pointer"
      )}
      onClick={() => router.push(node.path.alias)}
    >
      <div
        className={classNames(
          "relative pb-[56.7%] md:pb-[130%]",
          narrow ? "lg:pb-[115%]" : "lg:pb-[80%]"
        )}
      >
        <div className="block md:hidden">
          <Image
            src={node.field_image.image_style_uri.preview_teaser_large}
            {...node.field_image.resourceIdObjMeta}
            layout="fill"
          />
        </div>
        <div className="hidden md:block">
          <Image
            src={
              narrow
                ? node.field_image.image_style_uri.preview_teaser_narrow
                : node.field_image.image_style_uri.preview_teaser
            }
            {...node.field_image.resourceIdObjMeta}
            layout="fill"
          />
        </div>
        <div
          className="absolute top-1/3 bottom-0 inset-x-0 bg-gradient-to-t from-secondary-dark via-transparent"
          aria-hidden="true"
        />
        <div
          className="absolute top-0 right-0 w-[12vw] h-[12vw] bg-primary blur-xl rounded-full -translate-y-1/4 translate-x-1/4 opacity-0 scale-90 group-hover:opacity-50 group-hover:scale-100 transition-all duration-300"
          aria-hidden="true"
        />
      </div>
      <h3 className="absolute bottom-0 inset-x-0 p-4">
        <Link href={node.path.alias} passHref>
          <a className="text-sm font-extrabold text-white">{node.title}</a>
        </Link>
      </h3>
    </div>
  );
}
