import React, { useState } from "react";
import Image from "next/image";
import { A11y, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { useRouter } from "next/router";
import getDelayByIndex from "lib/getDelayByIndex";
import { Transition } from "@headlessui/react";
import classNames from "lib/classNames";

export default function NodeMilestonePreviewList({ isInViewport, nodes }) {
  const router = useRouter();
  const [swiper, setSwiper] = useState();

  return (
    <Swiper
      modules={[A11y, Navigation]}
      slidesPerView={1}
      breakpoints={{
        640: {
          slidesPerView: "auto",
        },
      }}
      a11y={{
        prevSlideMessage: "Tranche d'histoire suivante",
        nextSlideMessage: "Tranche d'histoire précédente",
      }}
      spaceBetween={200}
      onSwiper={setSwiper}
      className="relative py-20 px-10"
    >
      <div
        className="absolute top-32 w-screen left-1/2 -translate-x-1/2 mt-px h-px bg-secondary-light"
        aria-hidden="true"
      />

      {nodes.map((node, index) => {
        return (
          <SwiperSlide
            key={node.id}
            className="group relative w-auto cursor-pointer"
            onClick={() => router.push(node.path.alias)}
          >
            <div className="absolute top-1/2 left-1/4 w-[200px] h-[200px] bg-primary rounded-full blur-2xl opacity-0 scale-90 group-hover:opacity-30 group-hover:scale-100 transition-all duration-300 -translate-x-1/4 -translate-y-1/2" />

            <Transition
              show={isInViewport}
              className="relative flex flex-col gap-2"
            >
              <Transition.Child
                as="h3"
                enter={classNames(
                  "transition-all duration-300",
                  getDelayByIndex(index)
                )}
                enterFrom="opacity-0 translate-y-5"
                enterTo="opacity-100 translate-y-0"
                leave="transition-all duration-300"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-5"
                className="font-serif text-xl text-default-lighter"
              >
                {node.title}
              </Transition.Child>
              <div aria-hidden="true">
                <Transition.Child
                  enter={classNames(
                    "transition-all duration-300",
                    getDelayByIndex(index)
                  )}
                  enterFrom="opacity-0 scale-75"
                  enterTo="opacity-100 scale-100"
                  leave="transition-all duration-300"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-75"
                  className="inline-block p-2 border border-secondary-light bg-secondary rounded-full text-[0] leading-none"
                >
                  <span className="inline-block w-2 h-2 rounded-full bg-primary leading-none" />
                </Transition.Child>
              </div>
              <Transition.Child
                enter={classNames(
                  "transition-all duration-300",
                  getDelayByIndex(index)
                )}
                enterFrom="opacity-0 -translate-y-5"
                enterTo="opacity-100 translate-y-0"
                leave="transition-all duration-300"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-5"
                className="w-32"
              >
                <div className="w-full rounded-sm overflow-hidden">
                  <Image
                    src={node.field_image.image_style_uri.thumbnail}
                    alt={node.field_image.resourceIdObjMeta.alt}
                    width="125"
                    height="85"
                    layout="responsive"
                  />
                </div>
              </Transition.Child>
            </Transition>
          </SwiperSlide>
        );
      })}

      <button
        type="button"
        className="absolute right-0 top-32 mt-px p-3 -translate-y-1/2 border border-secondary-light bg-secondary rounded-full z-10 hover:bg-secondary-light hover:text-primary transition-colors"
        onClick={() => swiper.slideNext()}
      >
        <span className="sr-only">Suivant</span>
        <ChevronRightIcon className="h-3 w-3" />
      </button>
    </Swiper>
  );
}
