import { ArrowNarrowRightIcon } from "@heroicons/react/outline";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

export default function NodePatrinomyPreviewList({ nodes }) {
  const router = useRouter();

  return (
    <div className="xl:columns-2 gap-10">
      {nodes.map((node) => {
        return (
          <div
            key={node.id}
            className="group flex items-center gap-4 py-2 border-b border-secondary-light cursor-pointer"
            onClick={() => router.push(node.path.alias)}
          >
            <div className="w-2/12 xl:w-1/5">
              <Image
                src={node.field_image.image_style_uri.thumbnail}
                alt={node.field_image.resourceIdObjMeta.alt}
                width="125"
                height="85"
                layout="responsive"
                className="rounded-sm"
              />
            </div>
            <div className="relative flex-1">
              <h3 className="text-lg md:text-2xl font-serif font-medium md:-ml-12 group-hover:ml-0 transition-all duration-300">
                {node.title}
              </h3>
            </div>
            <div className="flex-initial">
              <Link href={node.path.alias} passHref>
                <a className="inline-block p-4 text-white rounded-full bg-transparent border border-secondary-light group-hover:bg-secondary-light transition-colors duration-300">
                  <ArrowNarrowRightIcon
                    className="h-4 w-4"
                    aria-hidden="true"
                  />
                  <span className="sr-only">En savoir plus</span>
                </a>
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
}
