import React from "react";
import NodeFigurePreviewTeaser from "components/Figure/NodeFigurePreviewTeaser";
import { Transition } from "@headlessui/react";

export default function NodeFigurePreviewList({ isInViewport, nodes }) {
  return (
    <>
      <Transition
        show={isInViewport}
        className="order-1 hidden lg:block w-1/3 xl:w-1/2 px-[25px]"
      >
        <div className="flex flex-row -mx-[25px]">
          <Transition.Child
            enter="transition-all duration-500 delay-300"
            enterFrom="opacity-0 -translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-10"
            className="w-full xl:w-1/2 px-[25px]"
          >
            <NodeFigurePreviewTeaser node={nodes[0]} />
          </Transition.Child>
          <Transition.Child
            enter="transition-all duration-500 delay-300"
            enterFrom="opacity-0 -translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-10"
            className="hidden xl:block w-[36.5%] px-[25px]"
          >
            <NodeFigurePreviewTeaser node={nodes[1]} narrow />
          </Transition.Child>
        </div>
      </Transition>

      <Transition
        show={isInViewport}
        className="order-3 w-full xl:w-2/3 xl:translate-x-1/3 px-[25px] mt-[50px]"
      >
        <div className="flex flex-col gap-10 md:gap-0 md:flex-row justify-center -mx-[25px]">
          <Transition.Child
            enter="transition-all duration-500 delay-500"
            enterFrom="opacity-0 translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-10"
            className="block lg:hidden w-full md:w-3/12 px-[25px]"
          >
            <NodeFigurePreviewTeaser node={nodes[0]} narrow />
          </Transition.Child>
          <Transition.Child
            enter="transition-all duration-500 delay-500"
            enterFrom="opacity-0 translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-10"
            className="block xl:hidden w-full md:w-3/12 px-[25px]"
          >
            <NodeFigurePreviewTeaser node={nodes[1]} narrow />
          </Transition.Child>
          <Transition.Child
            enter="transition-all duration-500 delay-500"
            enterFrom="opacity-0 translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-10"
            className="w-full md:w-3/12 xl:w-[36.5%] px-[25px]"
          >
            <NodeFigurePreviewTeaser node={nodes[2]} narrow />
          </Transition.Child>
          <Transition.Child
            enter="transition-all duration-500 delay-500"
            enterFrom="opacity-0 translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-10"
            className="w-full md:w-3/12 lg:w-1/3 xl:w-1/2 px-[25px]"
          >
            <NodeFigurePreviewTeaser node={nodes[3]} />
          </Transition.Child>
          <Transition.Child
            enter="transition-all duration-500 delay-500"
            enterFrom="opacity-0 translate-y-10"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-10"
            className="w-full md:w-3/12 lg:w-1/3 xl:w-1/2 px-[25px]"
          >
            <NodeFigurePreviewTeaser node={nodes[4]} />
          </Transition.Child>
        </div>
      </Transition>
    </>
  );
}
