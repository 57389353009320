import classNames from "lib/classNames";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";

export default function NodeSliceOfLifePreviewList({ nodes }) {
  const router = useRouter();

  return (
    <div className="flex gap-10">
      {nodes.map((node, index) => {
        const isEven = index % 2 === 0;
        const image = node.field_images[0];

        return (
          <div
            key={node.id}
            className={classNames(
              "group relative overflow-hidden rounded-md cursor-pointer w-[11.4vw] h-[15vw]",
              isEven && "-translate-y-12"
            )}
            onClick={() => router.push(node.path.alias)}
          >
            <Image
              src={image.image_style_uri.preview_teaser_narrow}
              {...image.resourceIdObjMeta}
              layout="fill"
            />
            <div
              className="absolute top-0 right-0 w-[12vw] h-[12vw] bg-primary blur-xl rounded-full -translate-y-1/4 translate-x-1/4 opacity-0 scale-90 group-hover:opacity-50 group-hover:scale-100 transition-all duration-300"
              aria-hidden="true"
            />
          </div>
        );
      })}
    </div>
  );
}
