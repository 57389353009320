import React, { useRef } from "react";
import { Parallax } from "react-scroll-parallax";
import { Transition } from "@headlessui/react";
import useIsInViewport from "hook/useIsInViewport";
import NodeSliceOfLifePreviewList from "components/SliceOfLife/NodeSliceOfLifePreviewList";
import Image from "next/image";
import Link from "next/link";
import Button from "components/Button";

export default function NodeSliceOfLifePreviewSection({ nodes }) {
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);

  return (
    <section id="lettre-de-chooz" className="relative py-20 lg:pt-28 z-10">
      <div className="absolute inset-0 overflow-hidden" aria-hidden="true">
        <Parallax
          translateY={[-15, 15]}
          className="absolute top-[4vw] left-0 w-[58.5vw] h-[32.7vw]"
          aria-hidden="true"
        >
          <Image
            src="/images/bg-06.jpg"
            width="1053"
            height="590"
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            className="opacity-20"
          />
          <div className="absolute top-0 inset-x-0 h-[35%] bg-gradient-to-b from-secondary" />
          <div className="absolute bottom-0 inset-x-0 h-[35%] bg-gradient-to-t from-secondary" />
          <div className="absolute inset-0 bg-gradient-to-l from-secondary" />
        </Parallax>
      </div>

      <div ref={ref} className="relative container mx-auto px-4">
        <Transition
          show={isInViewport}
          className="grid grid-cols-1 xl:grid-cols-2 gap-20 lg:gap-40"
        >
          <div className="w-full lg:w-2/3 xl:w-full">
            <Transition.Child
              enter="transition-all duration-500 delay-300"
              enterFrom="opacity-0 translate-y-10"
              enterTo="opacity-100 translate-y-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-10"
              className="counter text-primary"
            />

            <Transition.Child
              as="h2"
              enter="transition-all duration-500 delay-500"
              enterFrom="opacity-0 -translate-x-10"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-10"
              className="font-serif font-medium text-5xl mb-4"
            >
              La lettre de Chooz
            </Transition.Child>

            <Transition.Child
              enter="transition-all duration-500 delay-700"
              enterFrom="opacity-0 -translate-x-10"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-10"
              className="prose prose-white mx-0 max-w-none text-xl text-default-light"
            >
              <p>
                Le bulletin d&apos;information municipal permet de découvrir la
                vie et l&apos;évolution de notre village depuis 1992.
              </p>
            </Transition.Child>

            <Transition.Child
              enter="transition-all duration-500 delay-[900ms]"
              enterFrom="opacity-0 -translate-x-10"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-10"
              className="mt-10"
            >
              <Link href="/publications" passHref>
                <Button as="a">En savoir plus</Button>
              </Link>
            </Transition.Child>
          </div>

          <div className="w-full lg:w-2/3 xl:w-full xl:-mt-40">
            <div className="hidden xl:block mb-24">
              <NodeSliceOfLifePreviewList nodes={nodes} />
            </div>

            <Transition.Child
              enter="transition-all duration-500 delay-300"
              enterFrom="opacity-0 translate-y-10"
              enterTo="opacity-100 translate-y-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-10"
              className="counter-incremented text-primary"
            />

            <Transition.Child
              as="h2"
              enter="transition-all duration-500 delay-500"
              enterFrom="opacity-0 -translate-x-10"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-10"
              className="font-serif font-medium text-5xl mb-4"
            >
              Tranches de vie
            </Transition.Child>

            <Transition.Child
              enter="transition-all duration-500 delay-700"
              enterFrom="opacity-0 -translate-x-10"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-10"
              className="prose prose-white mx-0 max-w-none text-xl text-default-light"
            >
              <p>
                Moments de vie et partage du village au travers de différents
                diaporamas.
              </p>
            </Transition.Child>

            <Transition.Child
              enter="transition-all duration-500 delay-[900ms]"
              enterFrom="opacity-0 -translate-x-10"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-10"
              className="mt-10"
            >
              <Link href="/tranches-de-vie" passHref>
                <Button as="a">En savoir plus</Button>
              </Link>
            </Transition.Child>
          </div>
        </Transition>
        <Transition
          show={isInViewport}
          className="flex flex-col lg:flex-row lg:items-center gap-20 lg:gap-40 xl:px-40 pt-20 mt-20 border-t border-white/20"
        >
          <div className="flex-1">
            <Transition.Child
              as="h2"
              enter="transition-all duration-500 delay-500"
              enterFrom="opacity-0 -translate-x-10"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-10"
              className="font-serif font-medium text-5xl mb-4"
            >
              D&eacute;couvrez le charmant village de Chooz, dans les Ardennes
            </Transition.Child>
            <Transition.Child
              enter="transition-all duration-500 delay-700"
              enterFrom="opacity-0 -translate-x-10"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-10"
              className="prose prose-white mx-0 max-w-none text-xl text-default-light"
            >
              <p>
                Il poss&egrave;de une histoire fascinante, une culture riche et
                un patrimoine unique qui m&eacute;ritent d&apos;&ecirc;tre
                explor&eacute;s.
              </p>
            </Transition.Child>
          </div>
          <div className="flex-initial">
            <Transition.Child
              enter="transition-all duration-500 delay-[900ms]"
              enterFrom="opacity-0 -translate-x-10"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-10"
            >
              <Button
                as="a"
                href="https://www.chooz.com/"
                target="blank"
                inverse
              >
                Découvrir chooz
              </Button>
            </Transition.Child>
          </div>
        </Transition>
      </div>
    </section>
  );
}
