import React, { useRef } from "react";
import { Parallax } from "react-scroll-parallax";
import { Transition } from "@headlessui/react";
import useIsInViewport from "hook/useIsInViewport";
import Body from "components/Body";
import NodePatrinomyPreviewList from "components/Patrimony/NodePatrinomyPreviewList";

export default function NodePatrinomyPreviewSection({ info, nodes }) {
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);

  return (
    <section
      id={info.field_anchor}
      className="relative py-20 lg:pt-28 lg:pb-32 overflow-hidden"
    >
      <div
        className="absolute inset-0 bg-pattern bg-repeat opacity-10 bg-[length:4px]"
        aria-hidden="true"
      />

      <div ref={ref} className="relative container mx-auto px-4">
        <div
          className="absolute top-1/4 left-0 w-screen p-8 -translate-y-1/2 opacity-10"
          aria-hidden="true"
        >
          <Parallax
            translateY={[-15, 15]}
            className="bg-gradient-to-r from-white  via-transparent bg-clip-text text-transparent text-[210px] font-serif whitespace-nowrap overflow-hidden"
          >
            {info.title}
          </Parallax>
        </div>
        <div className="relative grid grid-cols-1 xl:grid-cols-2 gap-10">
          <div />
          <Transition show={isInViewport}>
            <Transition.Child
              enter="transition-all duration-500 delay-300"
              enterFrom="opacity-0 translate-y-10"
              enterTo="opacity-100 translate-y-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-10"
              className="counter text-primary"
            />

            <Transition.Child
              as="h2"
              enter="transition-all duration-500 delay-500"
              enterFrom="opacity-0 -translate-x-10"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-10"
              className="font-serif font-medium text-5xl mb-4"
            >
              {info.title}
            </Transition.Child>

            <Transition.Child
              enter="transition-all duration-500 delay-700"
              enterFrom="opacity-0 -translate-x-10"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-10"
              className="prose prose-white mx-0 max-w-none text-xl text-default-light"
            >
              <Body value={info.body.processed} />
            </Transition.Child>
          </Transition>
          <Transition
            show={isInViewport}
            enter="transition-opacity duration-500 delay-700"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="xl:col-span-2"
          >
            <NodePatrinomyPreviewList nodes={nodes} />
          </Transition>
        </div>
      </div>
    </section>
  );
}
