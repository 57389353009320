import React, { useRef } from "react";
import Image from "next/image";
import { Parallax } from "react-scroll-parallax";
import { Transition } from "@headlessui/react";
import useIsInViewport from "hook/useIsInViewport";
import Body from "components/Body";
import NodeFigurePreviewList from "components/Figure/NodeFigurePreviewList";

export default function NodeFigurePreviewSection({ info, nodes }) {
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);

  return (
    <section
      id={info.field_anchor}
      className="relative py-20 lg:pt-28 lg:pb-32 overflow-hidden"
    >
      <Parallax
        translateY={[-15, 15]}
        className="absolute bottom-0 right-0 w-[62vw] h-[43vw]"
        aria-hidden="true"
      >
        <Image
          src="/images/bg-02.jpg"
          width="1131"
          height="782"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          className="opacity-20"
        />
        <div className="absolute top-0 inset-x-0 h-[35%] bg-gradient-to-b from-secondary" />
        <div className="absolute inset-0 bg-gradient-to-r from-secondary" />
        <div
          className="absolute bottom-0 right-0 w-[36.2vw] h-[36.2vw] bg-primary blur-3xl opacity-50 rounded-full translate-y-1/2 translate-x-1/4"
          aria-hidden="true"
        />
      </Parallax>
      <div ref={ref} className="relative container mx-auto px-4">
        <div
          className="absolute top-1/2 left-0 w-screen p-8 -translate-y-1/2 opacity-10"
          aria-hidden="true"
        >
          <Parallax
            translateY={[-15, 15]}
            className="bg-gradient-to-r from-white  via-transparent bg-clip-text text-transparent text-[210px] font-serif whitespace-nowrap overflow-hidden"
          >
            {info.title}
          </Parallax>
        </div>
        <div className="relative">
          <div className="flex flex-row flex-wrap -mx-[25px]">
            <Transition
              show={isInViewport}
              className="order-2 lg:w-2/3 xl:w-1/2 px-[25px]"
            >
              <Transition.Child
                enter="transition-all duration-500 delay-300"
                enterFrom="opacity-0 translate-y-10"
                enterTo="opacity-100 translate-y-0"
                leave="transition-all duration-300"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-10"
                className="counter text-primary"
              />

              <Transition.Child
                as="h2"
                enter="transition-all duration-500 delay-500"
                enterFrom="opacity-0 -translate-x-10"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-300"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 -translate-x-10"
                className="font-serif font-medium text-5xl mb-4"
              >
                {info.title}
              </Transition.Child>

              <Transition.Child
                enter="transition-all duration-500 delay-700"
                enterFrom="opacity-0 -translate-x-10"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-300"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 -translate-x-10"
                className="prose prose-white mx-0 max-w-none text-xl text-default-light"
              >
                <Body value={info.body.processed} />
              </Transition.Child>
            </Transition>

            <NodeFigurePreviewList isInViewport={isInViewport} nodes={nodes} />
          </div>
        </div>
      </div>
    </section>
  );
}
